.board__1vvsj261 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.board__1vvsj262 {
  --grid-status-color: #18181b;
  width: 495px;
  user-select: none;
  display: grid;
  grid-template-rows: repeat(9, minmax(0, 1fr));
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.board__1vvsj262.good {
  --grid-status-color: #16a34a;
}
.board__1vvsj262.bad {
  --grid-status-color: #dc2626;
}
.board__1vvsj263 {
  --grid-status-color: #18181b;
  width: 530px;
  user-select: none;
  display: grid;
  grid-template-rows: 35px repeat(9, minmax(0, 1fr));
  grid-template-columns: 35px repeat(9, minmax(0, 1fr));
}
.board__1vvsj263.good {
  --grid-status-color: #16a34a;
}
.board__1vvsj263.bad {
  --grid-status-color: #dc2626;
}
.board__1vvsj264 {
  font-size: 16px;
  aspect-ratio: 1;
  position: relative;
}
.board__1vvsj265 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 solid #18181b;
}
.board__1vvsj265.bt {
  border-top-width: 1px;
}
.board__1vvsj265.bl {
  border-left-width: 1px;
}
.board__1vvsj265.bb {
  border-bottom-width: 1px;
}
.board__1vvsj265.br {
  border-right-width: 1px;
}
.board__1vvsj265.bt-thick {
  border-top: 2px solid var(--grid-status-color);
}
.board__1vvsj265.bl-thick {
  border-left: 2px solid var(--grid-status-color);
}
.board__1vvsj265.bb-thick {
  border-bottom: 2px solid var(--grid-status-color);
}
.board__1vvsj265.br-thick {
  border-right: 2px solid var(--grid-status-color);
}
.board__1vvsj265.bg-hovered-group {
  background-color: #e5e7eb;
}
.board__1vvsj265.bg-hovered {
  background-color: #bfdbfe;
}
.board__1vvsj266 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0 solid #60a5fa;
}
.board__1vvsj266.bt {
  border-top-width: 4px;
}
.board__1vvsj266.br {
  border-right-width: 4px;
}
.board__1vvsj266.bb {
  border-bottom-width: 4px;
}
.board__1vvsj266.bl {
  border-left-width: 4px;
}
.board__1vvsj267 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #111827;
}
.board__1vvsj267.default {
  color: #1e3a8a;
}
.board__1vvsj268 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.board__1vvsj269 {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2px;
  color: #374151;
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.board__1vvsj26a {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2px;
  color: #374151;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.board__1vvsj26a.font-sm {
  font-size: 14px;
}
.board__1vvsj26a.font-xs {
  font-size: 12px;
}
.board__1vvsj26a.font-xxs {
  font-size: 8px;
}